import { gql } from "@apollo/client"

export const GET_PLATFORM_CAMPAIGNS = gql`
  query GetPlatformCampaigns($clientId: Int!, $platform: Platform!, $platformIntegrationId: Int!, $platformAdvertiserId: String!) {
    getPlatformCampaigns(clientId: $clientId, platform: $platform, platformIntegrationId: $platformIntegrationId, platformAdvertiserId: $platformAdvertiserId) {
      __typename
      id
      clientId
      platform
      name
      currency
      platformSetupStatus
      managed
      managedCampaignData {
        isAutoApproved
        campaignType
        kpis {
          type
          name
          target
          min
          max
          weight
          pixelIds
          minimise
        }
      }
      platformCampaignId {
        __typename
        id
        platform
      }
      platformAdvertiserId {
        __typename
        id
        platform
      }
      assignees {
        clientId
        userId
        name {
          first
          last
        }
        email
      }
    }
  }
`
