import { gql } from "@apollo/client"

export const PLATFORM_CAMPAIGN_FRAGMENT = gql`
  fragment PlatformCampaignFragment on PlatformCampaign {
    __typename
    id
    clientId
    name
    currency
    platform
    managed
    platformSetupStatus
    managedCampaignData {
      strategy
      controlAlgorithmStrategy
      isAutoApproved
      campaignType
      buyingType
      budgetType
      marginType
      revenueModel
      margin
      minMargin
      maxMargin
      kpis {
        type
        name
        target
        min
        max
        weight
        pixelIds
        minimise
      }
      parameterGroups
    }
    platformAdvertiserId {
      __typename
      id
      platform
    }
    platformCampaignId {
      __typename
      id
      platform
    }
  }
`
