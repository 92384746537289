import React, { FC, useEffect, useState } from "react"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import getSymbolFromCurrency from "currency-symbol-map"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../../AppRoutes"
import { getKpiByName, KpiEntries, KpiTargetType } from "../../../components/forms/KpiSelector/KPI"
import { Option } from "../../../components/forms/SelectInput/SelectBaseUI"
import {
  BudgetType,
  ControlAlgorithmStrategy,
  Dv360ParameterGroup,
  FacebookParameterGroup,
  Fee,
  FeeType,
  KpiType,
  ManagedPlatformCampaignData,
  Maybe,
  OptimisationStrategy,
  Platform,
  PlatformCampaignSetupPayload,
  PlatformConversionPixel,
  RevenueModel,
  XandrParameterGroup,
} from "../../../generated/graphql"
import { CREATE_MANAGED_CAMPAIGN, GET_PLATFORM_CAMPAIGN, GET_PLATFORM_CONVERSION_PIXELS } from "../../../graphql"
import WizardLayout, { WizardStep } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import { StringKeyObject } from "../../../types"
import { convertDecimalPercentageToPercentage, convertPercentageToDecimalPercentage } from "../../../utils/numberUtils"
import { middleEllipsis } from "../../../utils/stringUtils"
import ManageCampaignData, { Step1Data, Step2Data } from "./ManageCampaignData"
import Step1 from "./Step1"
import Step2 from "./Step2"

const DEFAULT_MARGIN: Fee = {
  min: 0,
  max: 0.2,
  amount: 0.1,
  feeType: FeeType.MarginCostPlus,
}

const xandrParameterGroups = [
  XandrParameterGroup.LineItemBiddingModel,
  XandrParameterGroup.LineItemBudget,
  XandrParameterGroup.LineItemCreativesWeighting,
  XandrParameterGroup.LineItemDayParting,
  XandrParameterGroup.LineItemFoldPosition,
  XandrParameterGroup.LineItemFrequency,
  XandrParameterGroup.LineItemPlacementAudiencesDomains,
  XandrParameterGroup.LineItemRecency,
  XandrParameterGroup.LineItemViewabilityRate,
]

const dv360ParameterGroups = [
  Dv360ParameterGroup.LineItemBiddingModel,
  Dv360ParameterGroup.LineItemBudget,
  Dv360ParameterGroup.LineItemCreativesWeighting,
  Dv360ParameterGroup.LineItemDayParting,
  Dv360ParameterGroup.LineItemFoldPosition,
  Dv360ParameterGroup.LineItemFrequency,
  Dv360ParameterGroup.LineItemPlacementAudiencesDomains,
  Dv360ParameterGroup.LineItemRecency,
  Dv360ParameterGroup.LineItemViewabilityRate,
]

const facebookParameterGroups = [
  FacebookParameterGroup.AdSetLocation,
  FacebookParameterGroup.AdSetDemoLanguage,
  FacebookParameterGroup.AdSetAudiences,
  FacebookParameterGroup.AdSetBudget,
  FacebookParameterGroup.AdSetDayParting,
  FacebookParameterGroup.AdSetPlatform,
  FacebookParameterGroup.AdSetPlacementDeviceOs,
  FacebookParameterGroup.AdSetBrandSafety,
]

const parameterGroups: StringKeyObject<XandrParameterGroup[] | Dv360ParameterGroup[] | FacebookParameterGroup[]> = {
  [Platform.Xandr]: xandrParameterGroups,
  [Platform.Dv360]: dv360ParameterGroups,
  [Platform.Facebook]: facebookParameterGroups,
}

type CampaignDetails = {
  name: string
  id: string
}

interface CampaignSetupProps {
  user?: UserProfile
  clientId: string
  integrationId: string
  platform: string
  advertiserId: string
  campaignId: string
}

const CampaignSetup: FC<CampaignSetupProps> = ({ user, clientId, integrationId, platform, advertiserId, campaignId }) => {
  const navigate = useNavigate()
  const {
    loading: dataLoading,
    error: dataError,
    data: campaignData,
    called,
  } = useQuery(GET_PLATFORM_CAMPAIGN, {
    fetchPolicy: "network-only",
    variables: {
      clientId: Number(clientId) ?? undefined,
      platform,
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: advertiserId,
      platformCampaignId: campaignId,
    },
  })
  const {
    loading: pixelsLoading,
    error: pixelsError,
    data: pixelsData,
    called: pixelsCalled,
  } = useQuery(GET_PLATFORM_CONVERSION_PIXELS, {
    fetchPolicy: "network-only",
    variables: {
      clientId: Number(clientId) ?? undefined,
      platform,
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: platform === Platform.Facebook ? undefined : advertiserId,
    },
  })
  const [createCampaign, { loading: createLoading, error: createError }] = useMutation(CREATE_MANAGED_CAMPAIGN)

  const [dataFinalised, setDataFinalised] = useState(false)
  const [currencySymbol, setCurrencySymbol] = useState<string>("£")
  const [pixels, setPixels] = useState<Option[]>([])

  const { cache } = useApolloClient()

  const defaultWizardData: ManageCampaignData = [
    {
      strategy: OptimisationStrategy.CaQrtz003PPacing,
      controlAlgorithmStrategy: ControlAlgorithmStrategy.Default,
      campaignType: undefined,
      isAutoApproved: false,
      margin: `${DEFAULT_MARGIN.amount * 100}`,
      minMargin: `${(DEFAULT_MARGIN.min || 0) * 100}`,
      maxMargin: `${(DEFAULT_MARGIN.max || 0) * 100}`,
    },
    {
      kpis: [],
    },
    {
      parameterGroups: parameterGroups[platform],
    },
  ]

  const [initialData, setInitialData] = useState<ManageCampaignData>(defaultWizardData)
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails | undefined>(undefined)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: WizardStep<ManageCampaignData, any, any>[] = [
    { title: "Budget Type", component: Step1, props: { dataFinalised, user } }, //
    { title: "KPIs", component: Step2, props: { currencySymbol, createLoading, createError, pixels, platform } },
  ]

  useEffect(() => {
    if (called && !dataLoading && pixelsCalled && !pixelsLoading) {
      setDataFinalised(true)
    }
    if (dataError) {
      // TODO: error state
      logError("ERROR", dataError)
    }
    if (pixelsError) {
      logError("ERROR", pixelsError)
    }
    if (campaignData && campaignData.getPlatformCampaign) {
      setCurrencySymbol(getSymbolFromCurrency(campaignData.getPlatformCampaign.currency) ?? "£")
      setCampaignDetails({
        name: campaignData.getPlatformCampaign.name,
        id: `(${campaignData.getPlatformCampaign.platformCampaignId.id})`,
      })
    }
    if (pixelsData && pixelsData.getPlatformConversionPixels) {
      setPixels(
        pixelsData.getPlatformConversionPixels.map((pcp: PlatformConversionPixel) => ({
          label: middleEllipsis(`${pcp.name} - ${pcp.platformConversionPixelId.id}`),
          value: pcp.platformConversionPixelId.id,
        }))
      )
    }
    if (campaignData && campaignData.getPlatformCampaign.managedCampaignData) {
      const fees = campaignData.getPlatformCampaign.fees as Maybe<Array<Fee>>
      const marginFee = fees?.find((it) => it.feeType === FeeType.MarginCostPlus) ?? {
        ...DEFAULT_MARGIN,
      }
      setInitialData(
        mapExistingData({
          marginFee,
          managedCampaignData: campaignData.getPlatformCampaign.managedCampaignData,
          platform: platform as Platform,
        })
      )
    }
  }, [campaignData, dataError, called, dataLoading, pixelsData, pixelsError, pixelsCalled, pixelsLoading, platform])

  const onComplete = async (data: ManageCampaignData) => {
    await createCampaign({
      variables: {
        clientId: Number(clientId),
        platform,
        platformIntegrationId: Number(integrationId),
        platformAdvertiserId: advertiserId,
        platformCampaignId: campaignId,
        payload: buildMutationPayload(data, platform as Platform),
      },
    })

    const isManagedForTheFirstTime = campaignData && !campaignData.getPlatformCampaign.managedCampaignData
    if (isManagedForTheFirstTime) {
      const cacheId = `PlatformCampaignWithAssignees:${integrationId}-${platform}-${campaignId}`

      cache.modify({
        id: cacheId,
        fields: {
          managed() {
            return true
          },
          assignees(cachedAssignees) {
            return [
              ...cachedAssignees,
              {
                clientId,
                userId: user?.uid,
                name: user?.name,
                email: user?.email,
              },
            ]
          },
        },
      })
    }

    navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId))
  }

  return (
    <>
      {dataFinalised && (
        <WizardLayout title={campaignDetails?.name} subTitle={campaignDetails?.id} steps={steps} onComplete={onComplete} initialData={initialData} />
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildMutationPayload = (data: ManageCampaignData, platform: Platform): PlatformCampaignSetupPayload => {
  const step1Data = data["0"] as Step1Data
  const step2Data = data["1"] as Step2Data

  return {
    strategy: step1Data.strategy,
    controlAlgorithmStrategy: step1Data.controlAlgorithmStrategy,
    isAutoApproved: step1Data.isAutoApproved,
    campaignType: step1Data.campaignType,
    budgetType: BudgetType.Monetary,
    revenueModel: RevenueModel.CostPlus,
    margin: 0,
    fees: [
      {
        feeType: FeeType.MarginCostPlus,
        amount: Number(convertPercentageToDecimalPercentage(step1Data.margin)),
        min: step1Data.minMargin ? Number(convertPercentageToDecimalPercentage(step1Data.minMargin)) : undefined,
        max: step1Data.maxMargin ? Number(convertPercentageToDecimalPercentage(step1Data.maxMargin)) : undefined,
      },
    ],
    kpis: step2Data.kpis.map((kpi) => {
      let target = Number(kpi.target)
      let min = Number(kpi.minTarget)
      let max = Number(kpi.maxTarget)

      if (kpi.kpi.targetType === KpiTargetType.Percentage) {
        target = Number(convertPercentageToDecimalPercentage(kpi.target))
        min = Number(convertPercentageToDecimalPercentage(kpi.minTarget))
        max = Number(convertPercentageToDecimalPercentage(kpi.maxTarget))
      }

      return {
        type: kpi.type as KpiType,
        name: kpi.kpi.name,
        target,
        min,
        max,
        weight: Number(kpi.weight),
        pixelIds: kpi.pixelIds ?? undefined,
        minimise: kpi.kpi.minimise,
      }
    }),
    parameterGroups: parameterGroups[platform],
  }
}

const mapExistingData = (args: { managedCampaignData: ManagedPlatformCampaignData; marginFee: Fee; platform: Platform }): ManageCampaignData => [
  // Step 1 Data
  {
    strategy: args.managedCampaignData.strategy,
    controlAlgorithmStrategy: args.managedCampaignData.controlAlgorithmStrategy ?? undefined,
    campaignType: args.managedCampaignData.campaignType ?? undefined,
    isAutoApproved: args.managedCampaignData.isAutoApproved,
    margin: convertDecimalPercentageToPercentage({ value: args.marginFee.amount }) ?? "",
    minMargin: convertDecimalPercentageToPercentage({ value: args.marginFee.min ?? undefined }) ?? "",
    maxMargin: convertDecimalPercentageToPercentage({ value: args.marginFee.max ?? undefined }) ?? "",
  },
  // Step 2 Data
  {
    kpis: args.managedCampaignData.kpis.map((kpi) => {
      const kpiEntry = getKpiByName(KpiEntries, kpi.name)

      let target = kpi.target.toString()
      let minTarget = kpi.min.toString()
      let maxTarget = kpi.max.toString()

      if (kpiEntry.targetType === KpiTargetType.Percentage) {
        target = convertDecimalPercentageToPercentage({ value: kpi.target }) ?? ""
        minTarget = convertDecimalPercentageToPercentage({ value: kpi.min }) ?? ""
        maxTarget = convertDecimalPercentageToPercentage({ value: kpi.max }) ?? ""
      }

      return {
        type: kpi.type,
        kpi: kpiEntry,
        target,
        minTarget,
        maxTarget,
        pixelIds: kpi.pixelIds ?? undefined,
        weight: kpi.weight.toString(),
      }
    }),
  },
  // Step 3 Data
  {
    parameterGroups: parameterGroups[args.platform],
  },
]

export default CampaignSetup
