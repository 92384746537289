import { FC } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { HStack, Text, Tooltip, VStack } from "@chakra-ui/react"
import getSymbolFromCurrency from "currency-symbol-map"
import { CampaignType, CurrencyMetric, Metric } from "../../generated/graphql"
import { TileProps } from "../../layouts/TilesGrid"
import GridState from "../../layouts/TilesGrid/GridState"
import CampaignDashboardStateController from "../../pages/CampaignDashboardPage/types"
import colors from "../../theme/colors"
import Tile from "../Tile"
import MetricsStripLoader from "./MetricsStripLoader"
import { extractPixelNamesFromMetric, extractValueFromMetric, formatMetric, mapMetricNameToDisplayName } from "./utils"

const breakElementsIntoRows = (elements: JSX.Element[], itemsPerRow: number, numberOfRows: number) => {
  const rows = []

  for (let i = 0; i < numberOfRows; i++) {
    rows.push(
      <HStack key={`row-${i}`} w="100%" justifyContent="space-between">
        {elements.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow)}
      </HStack>
    )
  }

  return rows
}

type MetricsStripData = Metric[]

const MetricsStrip: FC<TileProps<CampaignDashboardStateController, GridState, MetricsStripData>> = ({ tileData, queryDetails, dashboardController }) => {
  const itemsPerRow = dashboardController.campaignType === CampaignType.Youtube ? 4 : 6

  const numberOfRows = Math.ceil(tileData?.length / itemsPerRow)

  const itemWidth = 100 / itemsPerRow

  const metrics = tileData.map((metric, index) => {
    const pixelNames = extractPixelNamesFromMetric(metric)
    return (
      <VStack key={`${metric.name}-${index}`} alignContent="start" width={`${itemWidth}%`}>
        <HStack>
          <Text as="b" color="brand.400" whiteSpace="nowrap">
            {mapMetricNameToDisplayName[metric.name]}
          </Text>
          {pixelNames.length > 0 && (
            <Tooltip aria-label={pixelNames} label={pixelNames} placement="right-start">
              <InfoIcon color={"brand.400"} w={4} h={4} display="flex" alignSelf="flex-start" />
            </Tooltip>
          )}
        </HStack>
        <Text fontSize="lg">
          {formatMetric({
            name: metric.name,
            value: extractValueFromMetric(metric),
            currencySymbol: getSymbolFromCurrency((metric as CurrencyMetric).currency),
          })}
        </Text>
      </VStack>
    )
  })

  return (
    <Tile
      loading={queryDetails?.loading}
      loader={<MetricsStripLoader />}
      error={queryDetails?.error}
      overflowY="scroll"
      maxH="224px"
      sx={{
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: colors.colors.brand["700"],
          // marginTop: "24px",
        },
        "::-webkit-scrollbar-thumb": {
          background: colors.colors.brand["200"],
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <VStack w="100%" h="fit-content">
        {!queryDetails?.loading && breakElementsIntoRows(metrics, itemsPerRow, numberOfRows)}
      </VStack>
    </Tile>
  )
}

export default MetricsStrip
