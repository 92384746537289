import React, { createContext, FC, useEffect, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Flex, useDisclosure, useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { ErrorBoundary } from "react-error-boundary"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import Button from "../../components/Button"
import ErrorFallBack from "../../components/ErrorFallback"
import { TransferListsData } from "../../components/forms/TransferLists"
import Loading from "../../components/Loading"
import Modal from "../../components/Modal"
import CampaignsTable from "../../components/tables/CampaignsTable"
import { CampaignsTableRowData } from "../../components/tables/CampaignsTable/CampaignsTable"
import { MutationAssignUsersToPlatformCampaignArgs, Platform, PlatformCampaignWithAssignees, RoleUserInput, UserProfile } from "../../generated/graphql"
import { ASSIGN_USERS_TO_PLATFORM_CAMPAIGN, GET_PLATFORM_CAMPAIGNS, GET_USER_PROFILES, SYNC_ADVERTISER } from "../../graphql"
import { useCurrentPlatformIntegration } from "../../hooks/navigation/useCurrentPlatformIntegration"
import { useCampaignsParams } from "../../hooks/navigationHooks"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canManage, canView } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import { logError, logUnhandledError } from "../../log"
import { Optional } from "../../types"
import AuthenticatedProps from "../AuthenticatedProps"
import AssignUsersForm, { assignUsersFormSchema } from "./components/AssignUsersForm"

export const CampaignsPageContext = createContext<Optional<TransferListsData>>(undefined)

type PlatformCampaignsPageProps = AuthenticatedProps

const CampaignsPage: FC<PlatformCampaignsPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformCampaignResourceUri, userResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const navigate = useNavigate()

  const { clientId: paramsClientId, integrationId, platform, advertiserId } = useCampaignsParams()

  const breadcrumbItems: BreadcrumbItemProps[] = [
    { text: "Integrations", onClick: () => navigate(RoutePaths.integrationsList.resolve(paramsClientId)) },
    {
      text: integrationId,
      onClick: () => navigate(RoutePaths.advertisersList.resolve(paramsClientId, integrationId)),
    },
    {
      text: "Advertisers",
      onClick: () => navigate(RoutePaths.advertisersList.resolve(paramsClientId, integrationId)),
    },
    {
      text: advertiserId,
      onClick: () => navigate(RoutePaths.campaignsList.resolve(paramsClientId, integrationId, platform, advertiserId)),
    },
    { text: "Campaigns" },
  ]
  if (user?.is59A) {
    breadcrumbItems.splice(0, 0, {
      text: "Clients",
      onClick: () => navigate(RoutePaths.clientsList.resolve()),
    })
    breadcrumbItems.splice(1, 0, {
      text: paramsClientId,
      onClick: () => navigate(RoutePaths.integrationsList.resolve(paramsClientId)),
    })
  }

  const clientId = paramsClientId ?? user?.clientId

  const { isOpen, onClose, onOpen } = useDisclosure()

  const { data: getUserProfilesData, refetch: refetchGetUserProfiles } = useQuery(GET_USER_PROFILES, { fetchPolicy: "network-only" })
  const [
    getPlatformCampaigns,
    { loading: getPlatformCampaignsLoading, error: getPlatformCampaignsError, data: getPlatformCampaignsData, refetch: refetchGetPlatformCampaignsData },
  ] = useLazyQuery(GET_PLATFORM_CAMPAIGNS, {
    variables: {
      clientId: Number(clientId),
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: advertiserId,
      platform,
    },
    fetchPolicy: "cache-first",
  })

  const [syncAdvertiser, { loading: syncAdvertiserLoading, error: syncAdvertiserError }] = useMutation(SYNC_ADVERTISER)

  const [assignUsersToPlatformCampaign, { error: assignUsersToPlatformCampaignError }] = useMutation(ASSIGN_USERS_TO_PLATFORM_CAMPAIGN)

  const [campaignHavingUsersAssigned, setCampaignHavingUsersAssigned] = useState<Optional<CampaignsTableRowData>>(undefined)
  const [allUsers, setAllUsers] = useState<UserProfile[]>([])
  const [availableUsers, setAvailableUsers] = useState<UserProfile[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserProfile[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [campaignData, setCampaignData] = useState<Array<PlatformCampaignWithAssignees>>()

  const defaultAssignUsersFormValues = {
    clientId: Number(clientId),
    platform: Platform.Xandr,
    platformCampaignId: "",
    platformIntegrationId: 0,
    users: [],
  }

  const {
    reset: resetAssignUsersForm,
    handleSubmit: handleSubmitAssignUsersForm,
    setValue: setAssignUsersFormValue,
    formState: { errors: assignUsersErrors },
  } = useForm<MutationAssignUsersToPlatformCampaignArgs>({
    resolver: zodResolver(assignUsersFormSchema),
    defaultValues: defaultAssignUsersFormValues,
  })

  const assignUsersToPlatformCampaignHandler = async (data: MutationAssignUsersToPlatformCampaignArgs) => {
    await assignUsersToPlatformCampaign({
      variables: {
        ...data,
        platform: campaignHavingUsersAssigned?.platform,
        platformCampaignId: campaignHavingUsersAssigned?.campaignId,
        platformIntegrationId: Number(integrationId),
      },
    })

    await closeAssignUsersFormHandler()
  }

  const onAssignUsersHandler = (campaign: CampaignsTableRowData, allUsers: UserProfile[]) => {
    const getRemainingAvailableUsers = () => {
      return allUsers
        .filter((item) => item.clientId === campaign.clientId)
        .filter((user) => !campaign.assignees?.some((assignee) => assignee.userId === user.userId))
    }

    const remainingAvailableUsers = getRemainingAvailableUsers()

    setSelectedUsers(campaign.assignees ?? [])
    setAvailableUsers(remainingAvailableUsers)
    setCampaignHavingUsersAssigned(campaign)
    onOpen()
  }

  const closeAssignUsersFormHandler = async () => {
    onClose()
    resetAssignUsersForm()
    await refetchGetUserProfiles()
    await refetchGetPlatformCampaignsData()
  }

  const toast = useToast()

  const { platformIntegration } = useCurrentPlatformIntegration()

  const syncWithDsp = async () => {
    await syncAdvertiser({
      variables: {
        clientId,
        platform,
        platformIntegrationId: Number(integrationId),
        platformAdvertiserId: advertiserId,
      },
    })

    toast({
      title: "Synchronising with DSP...",
      description: "Please refresh the page in a few minutes.",
      status: "success",
      duration: 9000,
      isClosable: true,
    })
  }

  useEffect(() => {
    if (getUserProfilesData) {
      setAllUsers(getUserProfilesData.getUserProfiles)
      setAvailableUsers(getUserProfilesData.getUserProfiles)
    }
  }, [getUserProfilesData])

  useEffect(() => {
    if (!selectedUserIds) {
      setAssignUsersFormValue("users", [])
    } else {
      const userData: RoleUserInput[] = []
      selectedUserIds.forEach((id) => {
        const user = allUsers.find((u) => u.userId === id)
        if (!user) return

        userData.push({
          clientId: user.clientId,
          userId: user.userId,
        })
      })
      setAssignUsersFormValue("users", userData)
    }
  }, [allUsers, selectedUserIds, setAssignUsersFormValue])

  useEffect(() => {
    if (syncAdvertiserError) {
      toast({
        title: "DSP synchronisation failed.",
        description: "Please try again later or contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })

      logError("ERROR", syncAdvertiserError)
    }
  }, [toast, syncAdvertiserError])

  useEffect(() => {
    if (Object.keys(assignUsersErrors).length) {
      logError("Assign users validation error", assignUsersErrors)
    }
  }, [assignUsersErrors])

  useEffect(() => {
    if (!getPlatformCampaignsData)
      setTimeout(async () => {
        await getPlatformCampaigns()
      }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getPlatformCampaignsData) {
      setCampaignData(getPlatformCampaignsData.getPlatformCampaigns)
    }
  }, [getPlatformCampaignsData])

  const pageTitle = "Campaigns"

  const fallbackErrorMessage = `There is a problem within ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
  Hello 59A Helpdesk,
    
  I encountered a problem in ${pageTitle} when doing...
  `
  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  const title = `Manage Assignees for ${campaignHavingUsersAssigned?.name ?? "campaign"}`

  const campaignsPageContextStore: TransferListsData = {
    canMakeAdminChanges: false,
    userEmail: user?.email ?? "",
    available: {
      items: availableUsers.map((item) => ({
        name: item.email,
        value: item.userId,
      })),
    },
    selected: {
      items: selectedUsers.map((item) => ({
        name: item.email,
        value: item.userId,
      })),
      setItems: setSelectedUserIds,
    },
  }

  // TODO: we need to introduce a new query to fetch the users for the current client without the need to have MANAGE users permissions
  const hasPermissions = canView(permissions, platformCampaignResourceUri) && canManage(permissions, userResourceUri)

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && hasPermissions && (
        <>
          <Flex justify="start" py={3} mb="-3.25rem">
            <Button isLoading={syncAdvertiserLoading} onClick={syncWithDsp} isDisabled={platformIntegration?.state !== "Active"}>
              Synchronise with DSP
            </Button>
          </Flex>
          <ErrorBoundary
            fallback={
              <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
            }
            onError={logUnhandledError}
          >
            <Modal
              hasForm
              isCentered
              isOpen={isOpen}
              onClose={closeAssignUsersFormHandler}
              title={title}
              subTitle={"Assignees will be notified of events concerning this campaign."}
              width="container.lg"
            >
              <CampaignsPageContext.Provider value={campaignsPageContextStore}>
                <AssignUsersForm
                  onSubmitHandler={assignUsersToPlatformCampaignHandler}
                  error={assignUsersToPlatformCampaignError}
                  handleSubmit={handleSubmitAssignUsersForm}
                  caller="CampaignsPage"
                />
              </CampaignsPageContext.Provider>
            </Modal>
            {allUsers.length > 0 && campaignData !== undefined && (
              <CampaignsTable
                data={campaignData}
                allUsers={allUsers}
                onAssignUsers={onAssignUsersHandler}
                error={getPlatformCampaignsError}
                loading={getPlatformCampaignsLoading}
              />
            )}
          </ErrorBoundary>
        </>
      )}
      {!permissionsLoading && !hasPermissions && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default CampaignsPage
