import { gql } from "@apollo/client"
import { PLATFORM_CAMPAIGN_FRAGMENT } from "../fragments"

export const GET_PLATFORM_CAMPAIGN = gql`
  query GetPlatformCampaign($clientId: Int!, $platform: Platform!, $platformIntegrationId: Int!, $platformAdvertiserId: String!, $platformCampaignId: ID!) {
    getPlatformCampaign(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
    ) {
      ...PlatformCampaignFragment
      fees {
        amount
        min
        max
        feeType
      }
    }
  }
  ${PLATFORM_CAMPAIGN_FRAGMENT}
`
