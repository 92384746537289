import { gql } from "@apollo/client"

export const UPSERT_SCHEDULE = gql`
  mutation upsertSchedule(
    $clientId: Int!
    $platformIntegrationId: Int!
    $scheduleId: String
    $state: ScheduleState
    $description: String!
    $scheduleRunType: ScheduleRunType!
    $scheduleCronExpression: String
    $platformDataType: PlatformDataType!
    $format: Format!
    $outputDestinations: [Destination!]!
    $granularity: Granularity!
  ) {
    upsertSchedule(
      clientId: $clientId
      platformIntegrationId: $platformIntegrationId
      scheduleId: $scheduleId
      state: $state
      description: $description
      scheduleRunType: $scheduleRunType
      scheduleCronExpression: $scheduleCronExpression
      platformDataType: $platformDataType
      format: $format
      outputDestinations: $outputDestinations
      granularity: $granularity
    ) {
      scheduleId
      state
      scheduleCronExpression
      nextRunTimestamp
      payload {
        activityType
        clientId
        platformDataType
        platform
        format
        platformIntegrationId
        outputDestinations
        granularity
      }
      description
    }
  }
`
