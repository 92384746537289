import getSymbolFromCurrency from "currency-symbol-map"
import { CampaignKpi, CampaignType, CurrencyMetric, DailyMetric, FloatMetric, IntMetric, Metric, MetricName } from "../../generated/graphql"
import {
  ClicksIcon,
  ConversionsEurIcon,
  ConversionsGbpIcon,
  ConversionsUsdIcon,
  CpaEurIcon,
  CpaGbpIcon,
  CpaUsdIcon,
  CpcEurIcon,
  CpcGbpIcon,
  CpcUsdIcon,
  CpmEurIcon,
  CpmGbpIcon,
  CpmUsdIcon,
  CpvEurIcon,
  CpvGbpIcon,
  CpvUsdIcon,
  CtrIcon,
  FrequencyIcon,
  ImpressionsIcon,
  VcpmIcon,
  VcrIcon,
  ViewabilityIcon,
} from "../../icons/metrics"
import { logError } from "../../log"
import colors from "../../theme/colors"
import { StringKeyObject } from "../../types"
import { enumEntryFromString } from "../../utils/enumUtils"
import { roundToUpToDecimals } from "../../utils/numberUtils"
import { CampaignTypeMetrics, DataPoint, MetricDataSet, MetricEntries, MetricIdentifier, MetricsComparisonPair, MetricType, OrderOfMetrics } from "./types"

export const mapMetricNameToDisplayName: Record<MetricName, string> = {
  [MetricName.Clicks]: "Clicks",
  [MetricName.Conversions]: "Conversions",
  [MetricName.ClickReach]: "Click Reach",
  [MetricName.CompleteViewsVideo]: "Complete Views",
  [MetricName.Cpa]: "CPA",
  [MetricName.Cpc]: "CPC",
  [MetricName.Cpcv]: "CPCV",
  [MetricName.Cpm]: "CPM",
  [MetricName.Cpv]: "CPV",
  [MetricName.Ctr]: "CTR",
  [MetricName.Frequency]: "Frequency",
  [MetricName.CoViewedFrequency]: "Co-Viewed Frequency",
  [MetricName.ViewableFrequency]: "Viewable Frequency",
  [MetricName.Impressions]: "Impressions",
  [MetricName.CoViewedImpressions]: "Co-Viewed Impressions",
  [MetricName.MeasurableImpressions]: "Measurable Impressions",
  [MetricName.ViewableImpressions]: "Viewable Impressions",
  [MetricName.ImpressionsReach]: "Impressions Reach",
  [MetricName.CoViewedImpressionsReach]: "Co-Viewed Impressions Reach",
  [MetricName.ViewableImpressionsReach]: "Viewable Impressions Reach",
  [MetricName.TotalReach]: "Total Reach",
  [MetricName.Roas]: "ROAS",
  [MetricName.Spend]: "Spend",
  [MetricName.Vcpm]: "vCPM",
  [MetricName.Viewability]: "Viewability",
  [MetricName.Vcr]: "VCR",
}

export const extractValueFromMetric = (metric?: Metric): number => {
  if (!metric) {
    return 0
  }

  if ("currency" in metric) {
    return roundToUpToDecimals({
      value: (metric as CurrencyMetric).valueFloat ?? 0,
    })
  } else if ("valueFloat" in metric) {
    return roundToUpToDecimals({
      value: (metric as FloatMetric).valueFloat ?? 0,
    })
  } else {
    return (metric as IntMetric).valueInt ?? 0
  }
}

const intlNumberFormat = new Intl.NumberFormat("en-GB", {
  maximumFractionDigits: 4,
})

type FormatMetricOpts = {
  name: MetricName | "BARS" | "LINE_ONE" | "LINE_TWO"
  value: number
  currencySymbol?: string
}

export const formatMetric = (opts: FormatMetricOpts) => {
  const { name, value, currencySymbol } = opts
  const metricEntry = MetricEntries.find((entry) => entry.name === name)
  if (!metricEntry) {
    logError("No MetricEntry found for ", name)
    return ""
  }

  const metricValueMappings: Record<MetricType, () => string> = {
    [MetricType.Value]: () => intlNumberFormat.format(value),
    [MetricType.Monetary]: () => `${currencySymbol}${intlNumberFormat.format(value)}`,
    [MetricType.Percentage]: () => `${intlNumberFormat.format(value * 100)}%`,
  }

  return metricValueMappings[metricEntry.type]()
}

export const extractPixelNamesFromMetric = (metric: Metric): string => {
  if ("pixels" in metric) {
    return metric.pixels?.map((pixel) => pixel.name).join(", ") ?? ""
  }
  return ""
}

type GetMetricIconOpts = {
  name: MetricName
  currency: string
  color?: string
  height?: number
  width?: number
}

export const getMetricIcon = ({ name, currency, color = colors.colors.brand["900"], height, width }: GetMetricIconOpts): JSX.Element | undefined => {
  const notImplementedIcon = <div>Icon Not Implemented Yet!</div>
  switch (name) {
    case MetricName.Clicks:
      return <ClicksIcon color={color} height={height} width={width} />
    case MetricName.Conversions:
      if (currency === "€") return <ConversionsEurIcon color={color} height={height} width={width} />
      if (currency === "£") return <ConversionsGbpIcon color={color} height={height} width={width} />
      if (currency === "$") return <ConversionsUsdIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpa:
      if (currency === "€") return <CpaEurIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpaGbpIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpaUsdIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpc:
      if (currency === "€") return <CpcEurIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpcGbpIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpcUsdIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpm:
      if (currency === "€") return <CpmEurIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpmUsdIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpmGbpIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpv:
      if (currency === "€") return <CpvEurIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpvUsdIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpvGbpIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Ctr:
      return <CtrIcon color={color} height={height} width={width} />
    case MetricName.Frequency:
      return <FrequencyIcon color={color} height={height} width={width} />
    case MetricName.Impressions:
      return <ImpressionsIcon color={color} height={height} width={width} />
    case MetricName.Vcpm:
      return <VcpmIcon color={color} height={height} width={width} />
    case MetricName.Vcr:
      return <VcrIcon color={color} height={height} width={width} />
    case MetricName.Viewability:
      return <ViewabilityIcon color={color} height={height} width={width} />
    default:
      break
  }
}

const getMetricTrioFromMetric = (metricIdentifier: MetricIdentifier): MetricIdentifier[] => {
  switch (metricIdentifier.name) {
    case MetricName.Cpa:
      return [metricIdentifier, { name: MetricName.Conversions, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Cpc:
      return [metricIdentifier, { name: MetricName.Clicks, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Cpcv:
      return [
        metricIdentifier,
        {
          name: MetricName.CompleteViewsVideo,
          pixelIds: metricIdentifier.pixelIds,
        },
        metricIdentifier,
      ]
    case MetricName.Cpm:
      return [metricIdentifier, { name: MetricName.Impressions, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Cpv:
      return [metricIdentifier, { name: MetricName.Viewability, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Ctr:
      return [metricIdentifier, { name: MetricName.Clicks, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Impressions:
      return [metricIdentifier, { name: MetricName.Cpm, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Roas:
      return [metricIdentifier, { name: MetricName.Impressions, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Vcpm:
      return [metricIdentifier, { name: MetricName.Impressions, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Viewability:
      return [metricIdentifier, { name: MetricName.Impressions, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    case MetricName.Vcr:
      return [metricIdentifier, { name: MetricName.Viewability, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
    default:
      return [metricIdentifier, { name: MetricName.Clicks, pixelIds: metricIdentifier.pixelIds }, metricIdentifier]
  }
}

const getMetricsToDisplay = (campaignKpis: CampaignKpi[], campaignType: CampaignType): MetricIdentifier[] => {
  const campaignKpisMetricIdentifiers = campaignKpis.map((campaignKpi) => {
    const metricName = enumEntryFromString(MetricName, campaignKpi.name.toUpperCase()) as MetricName

    const pixelIds =
      (metricName === MetricName.Cpa || metricName === MetricName.Conversions) && campaignKpi.pixelIds?.length && campaignKpi.pixelIds?.length > 0
        ? campaignKpi.pixelIds
        : undefined

    return {
      name: metricName,
      pixelIds,
    }
  })

  return campaignKpisMetricIdentifiers.concat(
    CampaignTypeMetrics[campaignType]
      .map((metric) => ({ name: metric, pixelIds: undefined }))
      .filter(
        (campaignTypeMetricIdentifier) =>
          !campaignKpisMetricIdentifiers.some((campaignKpisMetricIdentifier) => campaignKpisMetricIdentifier.name === campaignTypeMetricIdentifier.name)
      )
      .sort((a, b) => OrderOfMetrics.indexOf(a.name) - OrderOfMetrics.indexOf(b.name))
  )
}

export const getSelectableMetrics = (campaignKPIs: CampaignKpi[], campaignType: CampaignType, currencySymbol: string): MetricsComparisonPair[] => {
  const selectableMetrics: MetricsComparisonPair[] = []

  // Remove Spend as this is always the bars for the SelectableMetrics graph.
  const metricsToDisplay = getMetricsToDisplay(campaignKPIs, campaignType).filter((metricToDisplay) => metricToDisplay.name !== MetricName.Spend)

  const metricTrios = metricsToDisplay.map((metric) => getMetricTrioFromMetric(metric))

  metricTrios.forEach((metricTrio) => {
    // Check if already in selectableMetrics but not if a CPA metric and skip if so.
    const duplicateMetric = selectableMetrics.find(
      (selectableMetric) => selectableMetric.originalMetric === metricTrio[0] && selectableMetric.originalMetric.name !== MetricName.Cpa
    )

    if (duplicateMetric) return

    selectableMetrics.push({
      originalMetric: metricTrio[0],
      currencySymbol,
      isQRTZ: campaignKPIs.map((campaignKPI) => campaignKPI.name).indexOf(metricTrio[0].name) >= 0,
      metric1: metricTrio?.[1] ?? MetricName.Impressions,
      metric2: metricTrio?.[2] ?? MetricName.Cpm,
    })
  })

  selectableMetrics[0].isSelected = true

  // We only have 5 buttons for the SelectableMetricsGraph so grab the first 5 metrics.
  return selectableMetrics.slice(0, 5)
}

export const unPackDailyMetricsForTrendGraphs = (
  dailyMetrics: DailyMetric[], //
  campaignType: CampaignType,
  campaignKpis?: CampaignKpi[]
): MetricDataSet[] => {
  const metrics: MetricDataSet[] = []

  // Only get the first 5 metrics to display as there are only 5 Trend Graphs
  const metricsToDisplay = getMetricsToDisplay(campaignKpis ?? [], campaignType).slice(0, 5)

  metricsToDisplay.forEach((metricToDisplay) => {
    const qrtzKpi: Partial<Pick<MetricDataSet, "pixels" | "target">> = {}

    if (campaignKpis) {
      const metric = dailyMetrics[0]?.metrics.find((metric, index) =>
        metricToDisplay.pixelIds && metricToDisplay.pixelIds.length > 0
          ? metric.name === metricToDisplay.name &&
            metricToDisplay.pixelIds.some((metricInputPixelId) => metric?.pixels?.some((metricPixel) => metricPixel?.id === metricInputPixelId))
          : metric.name === metricToDisplay.name
      )

      const campaignKpi = campaignKpis.find(
        (kpi) => kpi.name.toUpperCase() === metricToDisplay.name && (kpi.pixelIds ?? undefined) === metricToDisplay.pixelIds
      )

      qrtzKpi["pixels"] = metric?.pixels ?? undefined
      qrtzKpi["target"] = campaignKpi?.target
    }

    metrics.push({
      ...qrtzKpi,
      ...(MetricEntries.find((entry) => entry.name === metricToDisplay.name) ?? MetricEntries[0]),
      data: dailyMetrics.map((dailyMetric) => {
        const metric = dailyMetric.metrics.find((metric) =>
          metricToDisplay.pixelIds
            ? metric.name === metricToDisplay.name &&
              metricToDisplay.pixelIds.some((metricToDisplayPixelId) => metric?.pixels?.some((metricPixel) => metricPixel?.id === metricToDisplayPixelId))
            : metric.name === metricToDisplay.name
        )

        const metricValue = extractValueFromMetric(metric)

        return {
          date: dailyMetric.date,
          hover: formatMetric({
            name: metricToDisplay.name,
            value: metricValue,
            currencySymbol: getSymbolFromCurrency((metric as CurrencyMetric).currency),
          }),
          value: metricValue,
        }
      }) as DataPoint[],
    })
  })

  return metrics
}

export type GraphDataType = "BARS" | "LINE_ONE" | "LINE_TWO"

export const unPackDailyMetricsForGraphs = (
  dailyMetrics: DailyMetric[], //
  metricsToDisplay: MetricIdentifier[],
  campaignKpis?: CampaignKpi[]
): Record<GraphDataType, MetricDataSet> => {
  const metricsObjects: StringKeyObject<MetricDataSet> = {}

  metricsToDisplay.forEach((metricToDisplay, index) => {
    const metricName = metricToDisplay.name

    let metricNameKey: GraphDataType = "BARS"
    switch (index) {
      case 1:
        metricNameKey = "LINE_ONE"
        break
      case 2:
        metricNameKey = "LINE_TWO"
        break
    }

    const qrtzKpi: Partial<Pick<MetricDataSet, "pixels" | "target">> = {}
    if (campaignKpis) {
      const metric = dailyMetrics[0]?.metrics.find((metric, index) =>
        metricToDisplay.pixelIds && metricToDisplay.pixelIds.length > 0
          ? metric.name === metricName &&
            metricToDisplay.pixelIds.some((metricInputPixelId) => metric?.pixels?.some((metricPixel) => metricPixel?.id === metricInputPixelId))
          : metric.name === metricName
      )
      const campaignKpi = campaignKpis.find((kpi) => kpi.name.toUpperCase() === metricName && kpi.pixelIds === metricToDisplay.pixelIds)
      qrtzKpi["pixels"] = metric?.pixels ?? undefined
      qrtzKpi["target"] = campaignKpi?.target
    }

    metricsObjects[metricNameKey] = {
      ...qrtzKpi,
      ...(MetricEntries.find((entry) => entry.name === metricName) ?? MetricEntries[0]),
      data: dailyMetrics.map((dailyMetric) => {
        const pixelIds = qrtzKpi.pixels?.map((pixel) => pixel.id)

        const metric = dailyMetric.metrics.find((metric) =>
          pixelIds
            ? metric.name === metricName && pixelIds.some((pixelId) => metric?.pixels?.some((metricPixel) => metricPixel?.id === pixelId))
            : metric.name === metricName
        )

        const metricValue = extractValueFromMetric(metric)

        return {
          date: dailyMetric.date,
          hover: formatMetric({
            name: metricName,
            value: metricValue,
            currencySymbol: getSymbolFromCurrency((metric as CurrencyMetric).currency),
          }),
          value: metricValue,
        }
      }) as DataPoint[],
    }
  })

  return metricsObjects as Record<GraphDataType, MetricDataSet>
}
