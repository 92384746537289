import React, { FC } from "react"
import { Flex, HStack } from "@chakra-ui/react"
import { Control, FieldError, FieldErrors, UseFormWatch } from "react-hook-form"
import { KpiName, Platform } from "../../../generated/graphql"
import { Optional } from "../../../types"
import { getEnumKeyByEnumValue } from "../../../utils/enumUtils"
import CurrencyInput from "../CurrencyInput"
import ErrorText from "../ErrorText"
import IntegerInput from "../IntegerInput"
import PercentageInput from "../PercentageInput"
import SelectInput from "../SelectInput"
import { Option } from "../SelectInput/SelectBaseUI"
import TextInput from "../TextInput"
import { FormKpi, FormValues, getKpiByName, KPI, KpiFullName, KpiTargetType } from "./KPI"
import KpiConstraints from "./KpiConstraints"

interface KpiSelectorProps {
  kpis: KPI[]
  pixels?: Option[]
  platform: Platform
  required?: boolean
  zIndex: number
  hasConstraints?: boolean
  hasWeight?: boolean
  selectDisabled?: boolean
  currencySymbol: string
  fieldName: keyof FormValues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FormValues, any>
  errors: FieldErrors<FormValues>
  watch: UseFormWatch<FormValues>
}

const retrieveErrorMessage = (fieldName: keyof FormValues, errors: FieldErrors<FormValues>): Optional<string> => {
  if (fieldName.includes("secondaryKpis")) {
    const elementNumber = Number(fieldName.slice(-1))
    return (errors.secondaryKpis?.[elementNumber] as FieldError)?.message
  }
  return (errors.mainKpi as FieldError)?.message
}

const KpiSelector: FC<KpiSelectorProps> = ({
  kpis,
  pixels,
  platform,
  required = true,
  zIndex,
  fieldName,
  hasConstraints = false,
  hasWeight = false,
  selectDisabled = false,
  currencySymbol,
  control,
  errors,
  watch,
}) => {
  const kpiOptions = kpis.map(({ name, fullName }) => {
    const label = getEnumKeyByEnumValue(KpiName, name) === getEnumKeyByEnumValue(KpiFullName, fullName) ? `${name} - ${fullName}` : name
    return {
      label: label,
      value: name,
    }
  })

  const watchFieldName = watch(fieldName) as Optional<FormKpi>

  const selectedKpi = watchFieldName?.name ? getKpiByName(kpis, watchFieldName?.name) : undefined

  const errorMessage = retrieveErrorMessage(fieldName, errors)

  return (
    <>
      <HStack align="flex-end" justifyContent="flex-start">
        <SelectInput //
          isDisabled={selectDisabled}
          aria-label="Select KPI"
          control={control}
          name={`${fieldName}.name` as keyof FormValues}
          label="KPI Type"
          placeholder="Select KPI Type ..."
          options={kpiOptions}
          width={96}
          zIndex={zIndex}
        />
        {selectedKpi && (
          <HStack align="flex-end">
            {selectedKpi?.targetType === KpiTargetType.Integer && (
              <IntegerInput //
                size="md"
                name={`${fieldName}.target.integer` as keyof FormValues}
                control={control}
                label="Target"
                tooltip="The target for this KPI expressed as an integer value"
                width={48}
              />
            )}

            {selectedKpi?.targetType === KpiTargetType.Monetary && ( //
              <CurrencyInput //
                size="md"
                name={`${fieldName}.target.monetary` as keyof FormValues}
                control={control}
                symbol={currencySymbol}
                label="Target"
                tooltip="The target for this KPI as a currency value"
              />
            )}

            {selectedKpi?.targetType === KpiTargetType.Percentage && (
              <PercentageInput //
                size="md"
                name={`${fieldName}.target.percentage` as keyof FormValues}
                control={control}
                label={"Target"}
                tooltip="The target for this KPI expressed as a percentage"
              />
            )}

            {hasConstraints && selectedKpi && <KpiConstraints currencySymbol={currencySymbol} control={control} fieldName={fieldName} kpi={selectedKpi} />}
            {hasWeight && (
              <IntegerInput //
                max={10}
                size="md"
                control={control}
                name={`${fieldName}.weight` as keyof FormValues}
                label="Weight"
                tooltip="The weight compared with others KPIs as a integer less that or equal to 10"
              />
            )}

            {selectedKpi.requiresPixelId &&
              (platform === Platform.Dv360 ? (
                <TextInput //
                  error={errors[`${fieldName}.pixelIds` as keyof FormValues] as FieldError}
                  size="md"
                  name={`${fieldName}.pixelIds` as keyof FormValues}
                  control={control}
                  label="Pixel Id"
                  placeholder="Pixel Id"
                  tooltip="The pixel id for this KPI"
                />
              ) : (
                <SelectInput //
                  styles={{ paddingTop: "1.2rem" }}
                  error={errors[`${fieldName}.pixelIds` as keyof FormValues] as FieldError}
                  aria-label="Select pixels"
                  control={control}
                  name={`${fieldName}.pixelIds` as keyof FormValues}
                  label="Pixel Ids"
                  placeholder="Select pixels ..."
                  options={pixels ?? []}
                  isMulti={true}
                  width={96}
                  zIndex={zIndex}
                />
              ))}
          </HStack>
        )}
      </HStack>
      <Flex h={8}>{errorMessage && <ErrorText message={errorMessage} />}</Flex>
    </>
  )
}

export default KpiSelector
