import { logError } from "../log"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumToMap = (enumeration: any): Map<string, string | number> => {
  const map = new Map<string, string | number>()
  for (const key in enumeration) {
    if (!isNaN(Number(key))) continue

    const val = enumeration[key] as string | number

    if (val !== undefined && val !== null) map.set(key, val)
  }

  return map
}

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(myEnum: T, enumValue?: string): keyof T | null => {
  if (enumValue === undefined) return null
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumToLabelValueArray = (enumeration: any): Array<{ label: string; value: string }> => {
  const map = enumToMap(enumeration)
  return Array.from(map.entries()).map((map) => ({ label: map[0], value: String(map[1]) }))
}

// TODO: Barns fix return typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumEntryFromString = (enumeration: any, stringValue?: string): null | any => {
  if (!stringValue) {
    return null
  }
  const entry = Object.values(enumeration).find((value) => value === stringValue)

  if (!entry) {
    logError(`No entry ${stringValue} found for enum ${enumeration}`)
    return null
  }
  return entry
}
