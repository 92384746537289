import React, { FC, useEffect, useMemo, useState } from "react"
import { HStack } from "@chakra-ui/react"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { RoutePaths } from "../../../AppRoutes"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { Client } from "../../../generated/graphql"
import usePermissions, { canView } from "../../../hooks/usePermissions"
import useResourceUris from "../../../hooks/useResourceUris"
import useSettings from "../../../hooks/useSettings"
import { Optional } from "../../../types"
import Button from "../../Button"
import PageTable from "../PageTable"

interface TableRowData {
  clientId: number
  name: string
}

const toTableRow = (data: Client[]): TableRowData[] => data.map((v, i) => ({ key: i, ...v }))

const columnHelper = createColumnHelper<TableRowData>()

interface ClientsTableProps {
  data?: Optional<Client[]>
  loading: boolean
}

const ClientsTable: FC<ClientsTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { platformIntegrationResourceUri } = useResourceUris()
  const { permissions } = usePermissions()

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.clients)

  useEffect(() => {
    if (pageIndex !== paginationSettings.clients.pageIndex || pageSize !== paginationSettings.clients.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        clients: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const tableColumns = useMemo(
    () =>
      [
        columnHelper.accessor("clientId", {
          id: "clientId",
          cell: (info) => info.getValue(),
          header: () => <span>Client Id</span>,
          footer: (props) => props.column.id,
        }),
        columnHelper.accessor("name", {
          id: "name",
          cell: (info) => info.getValue(),
          header: () => <span>Name</span>,
          footer: (props) => props.column.id,
        }),
        columnHelper.display({
          id: "actions",
          header: () => <span>Actions</span>,
          cell: ({ row }) => (
            <HStack>
              {canView(permissions, platformIntegrationResourceUri) && (
                <Button
                  onClick={() =>
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    navigate(RoutePaths.integrationsList.resolve(row.original!.clientId.toString()))
                  }
                  isInline={true}
                >
                  View Integrations
                </Button>
              )}
            </HStack>
          ),
        }),
      ] as ColumnDef<TableRowData, unknown>[],
    [navigate, permissions, platformIntegrationResourceUri]
  )

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as TableRowData[]
  }, [data])

  return (
    <PageTable
      pagination={{ pageIndex, pageSize }}
      onPaginationChange={setPagination}
      loading={loading}
      defaultSort={[{ desc: false, id: "clientId" }]}
      hasGlobalFilter={true}
      heightOtherElementsRem={21.05}
      tableColumns={tableColumns}
      tableRows={tableRows}
    />
  )
}

export default ClientsTable
