import { gql } from "@apollo/client"

export const UPSERT_ROLE = gql`
  mutation upsertRole($clientId: Int!, $roleId: Int, $name: String!, $description: String!, $permissions: UpsertRolePermissionsInput!) {
    upsertRole(clientId: $clientId, roleId: $roleId, name: $name, description: $description, permissions: $permissions) {
      clientId
      roleId
      name
      description
    }
  }
`
