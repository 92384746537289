import { gql } from "@apollo/client"

export const GET_CAMPAIGN_CREATIVES_WITH_DELIVERY = gql`
  query getCampaignCreativesWithDelivery(
    $clientId: Int!
    $platformIntegrationId: Int!
    $platform: Platform!
    $platformAdvertiserId: String!
    $platformCampaignId: ID!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    getCampaignCreativesWithDelivery(
      clientId: $clientId
      platformIntegrationId: $platformIntegrationId
      platform: $platform
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      platformCreativeId {
        id
        platform
      }
      name
      width
      height
    }
  }
`
