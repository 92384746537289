import { FC, useMemo } from "react"
import { ApolloError, useQuery } from "@apollo/client"
import { Box } from "@chakra-ui/react"
import { Control, UseFormHandleSubmit, UseFormWatch } from "react-hook-form"
import { boolean, number, object, string } from "yup"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import SelectInput from "../../../components/forms/SelectInput"
import SwitchInput from "../../../components/forms/SwitchInput"
import TextInput from "../../../components/forms/TextInput"
import { Client } from "../../../generated/graphql"
import { GET_CLIENTS } from "../../../graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import { CustomGraphQLError } from "../../../types"
import { is59AClient } from "../UserManagement"

export interface UserFormValues {
  clientId: number
  isSuperUser?: boolean
  isAdministrator?: boolean
  email: string
  firstName: string
  lastName: string
}

export const userFormValuesSchema = object({
  clientId: number().required(),
  isSuperUser: boolean(),
  isAdministrator: boolean(),
  email: string().required().trim(),
  firstName: string().required().trim(),
  lastName: string().required().trim(),
})

type UserSetupFormProps = {
  onSubmitHandler: (data: UserFormValues) => void
  error?: ApolloError
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<UserFormValues, any>
  handleSubmit: UseFormHandleSubmit<UserFormValues>
  submitLoading?: boolean
  watch: UseFormWatch<UserFormValues>
  user: UserProfile
  fieldsState: {
    clientId?: number
    email?: string
  }
}

const UserSetupForm: FC<UserSetupFormProps> = ({ error, onSubmitHandler, control, handleSubmit, submitLoading, watch, user, fieldsState }) => {
  const { loading: clientsLoading, error: clientsError, data: clientsData } = useQuery(GET_CLIENTS, { fetchPolicy: "network-only" })

  const clientOptions: { label: string; value: string }[] = useMemo(() => {
    if (clientsError) {
      logError(clientsError)
    }
    if (clientsData) {
      return clientsData.getClients.map((client: Client) => ({
        label: `${client.clientId} - ${client.name}`,
        value: client.clientId,
      }))
    }
  }, [clientsError, clientsData])

  const watchClientId = watch("clientId")

  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <Box minHeight={user.is59A ? "34rem" : "23rem"}>
      <>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {user.is59A && !clientsLoading && clientOptions?.length && (
            <>
              <SelectInput label="Client" name="clientId" options={clientOptions} control={control} isInModal={true} zIndex={9} />
              {is59AClient(watchClientId) ? (
                <>
                  <SwitchInput //
                    name="isSuperUser"
                    label="59A Super User"
                    tooltip="59A super users can see administration pages and have a different UI to regular SaaS users"
                    control={control}
                  />
                  <SwitchInput //
                    name="isAdministrator"
                    label="Administrator"
                    tooltip="Administrator users have all permissions enabled for their client"
                    control={control}
                  />
                </>
              ) : (
                <SwitchInput //
                  name="isAdministrator"
                  label="Administrator"
                  tooltip="Administrator users have all permissions enabled for their client"
                  control={control}
                />
              )}
            </>
          )}
          <TextInput label="Email" name="email" control={control} />
          <TextInput label="First Name" name="firstName" control={control} />
          <TextInput label="Last Name" name="lastName" control={control} />
          <ErrorText
            height={10}
            message={
              errorType
                ? mapErrorTypeToMessage({
                    errorType,
                    error,
                    clientId: fieldsState.clientId,
                    email: fieldsState.email,
                  })
                : ""
            }
            size="small"
          />
          <Button type="submit" isDisabled={submitLoading}>
            Submit
          </Button>
        </form>
      </>
    </Box>
  )
}

export default UserSetupForm
