import React, { FC } from "react"
import { withAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import "./theme/css/amplify-custom.css"
import { Route, Routes } from "react-router-dom"
import useAuth from "./hooks/useAuth"
import { AuthStage } from "./models/AuthState"
import AdvertisersPage from "./pages/AdvertisersPage"
import AuthenticatedProps from "./pages/AuthenticatedProps"
import CampaignDashboardPage from "./pages/CampaignDashboardPage"
import CampaignSetupPage from "./pages/CampaignSetupPage"
import CampaignsPage from "./pages/CampaignsPage"
import ClientsPage from "./pages/ClientsPage"
import HomePage from "./pages/HomePage"
import IntegrationSetupDV360Page from "./pages/IntegrationSetupDV360Page"
import IntegrationSetupFacebookPage from "./pages/IntegrationSetupFacebookPage"
import IntegrationSetupIndexPage from "./pages/IntegrationSetupIndexPage"
import IntegrationSetupXandrPage from "./pages/IntegrationSetupXandrPage"
import IntegrationsPage from "./pages/IntegrationsPage"
import UserManagementPage from "./pages/UserManagementPage"
import { StringKeyObject } from "./types"
import { foldLeft } from "./utils/functionalUtils"

const interpolatePath = (path: string, vars: StringKeyObject<string>) => foldLeft(path, Object.entries(vars), (p, [k, v]) => p.replaceAll(`:${k}`, v))

const clientsPath = "/clients"
const clientSetupPath = "/clients/setup"

const integrationsPath = "/clients/:clientId/integrations"
const integrationsSetupIndexPath = "/clients/:clientId/integrations/setup"
const integrationsSetupDV360Path = "/clients/:clientId/integrations/setup/dv360"
const integrationsSetupFacebookPath = "/clients/:clientId/integrations/setup/facebook"
const integrationsSetupXandrPath = "/clients/:clientId/integrations/setup/xandr"

const advertisersPath = "/clients/:clientId/integration/:integrationId/advertisers"

const campaignsPath = "/clients/:clientId/integration/:integrationId/advertisers/:platform/:advertiserId/campaigns"
const campaignManagementPath = "/clients/:clientId/integration/:integrationId/advertisers/:platform/:advertiserId/campaigns/:campaignId/manage"
const campaignDashboardPath = "/clients/:clientId/integration/:integrationId/advertisers/:platform/:advertiserId/campaigns/:campaignId/dashboard"

const userManagementPath = "/user-management"

export const RoutePaths = {
  clientsList: {
    path: clientsPath,
    resolve: () => clientsPath,
  },
  clientSetup: {
    path: clientSetupPath,
    resolve: () => clientSetupPath,
  },
  integrationsList: {
    path: integrationsPath,
    resolve: (clientId: string) => interpolatePath(integrationsPath, { clientId }),
  },
  integrationSetupIndex: {
    path: integrationsSetupIndexPath,
    resolve: (clientId: string) => interpolatePath(integrationsSetupIndexPath, { clientId }),
  },
  integrationSetupDV360: {
    path: integrationsSetupDV360Path,
    resolve: (clientId: string) => interpolatePath(integrationsSetupDV360Path, { clientId }),
  },
  integrationSetupFacebook: {
    path: integrationsSetupFacebookPath,
    resolve: (clientId: string) => interpolatePath(integrationsSetupFacebookPath, { clientId }),
  },
  integrationSetupXandr: {
    path: integrationsSetupXandrPath,
    resolve: (clientId: string) => interpolatePath(integrationsSetupXandrPath, { clientId }),
  },
  advertisersList: {
    path: advertisersPath,
    resolve: (clientId: string, integrationId: string) =>
      interpolatePath(advertisersPath, {
        clientId,
        integrationId,
      }),
  },
  campaignsList: {
    path: campaignsPath,
    resolve: (clientId: string, integrationId: string, platform: string, advertiserId: string) =>
      interpolatePath(campaignsPath, {
        clientId,
        integrationId,
        platform,
        advertiserId,
      }),
  },
  campaignManagement: {
    path: campaignManagementPath,
    resolve: (clientId: string, integrationId: string, platform: string, advertiserId: string, campaignId: string) =>
      interpolatePath(campaignManagementPath, {
        clientId,
        integrationId,
        platform,
        advertiserId,
        campaignId,
      }),
  },
  campaignDashboard: {
    path: campaignDashboardPath,
    resolve: (clientId: string, integrationId: string, platform: string, advertiserId: string, campaignId: string) =>
      interpolatePath(campaignDashboardPath, {
        clientId,
        integrationId,
        platform,
        advertiserId,
        campaignId,
      }),
  },
  userManagement: {
    path: userManagementPath,
    resolve: () => userManagementPath,
  },
}

const authenticated = (element: FC<AuthenticatedProps>, props?: AuthenticatedProps): React.ReactElement =>
  React.createElement(withAuthenticator(element, { hideSignUp: true }), props)

const AppRoutes: FC = () => {
  const { user, authStage } = useAuth()

  return (
    <Routes>
      <>
        {authStage === AuthStage.Authenticated && !user?.is59A ? (
          <>
            <Route index element={authenticated(IntegrationsPage)} />

            <Route path={RoutePaths.integrationsList.path} element={authenticated(IntegrationsPage)} />
            <Route path={RoutePaths.integrationSetupIndex.path} element={authenticated(IntegrationSetupIndexPage)} />
            <Route path={RoutePaths.integrationSetupDV360.path} element={authenticated(IntegrationSetupDV360Page)} />
            <Route path={RoutePaths.integrationSetupFacebook.path} element={authenticated(IntegrationSetupFacebookPage)} />
            <Route path={RoutePaths.integrationSetupXandr.path} element={authenticated(IntegrationSetupXandrPage)} />

            <Route path={RoutePaths.advertisersList.path} element={authenticated(AdvertisersPage)} />

            <Route path={RoutePaths.campaignsList.path} element={authenticated(CampaignsPage)} />
            <Route path={RoutePaths.campaignManagement.path} element={authenticated(CampaignSetupPage)} />
            <Route path={RoutePaths.campaignDashboard.path} element={authenticated(CampaignDashboardPage)} />

            <Route path={RoutePaths.userManagement.path} element={authenticated(UserManagementPage)} />

            <Route path="/*" element={authenticated(IntegrationsPage)} />
          </>
        ) : authStage === AuthStage.Authenticated && user?.is59A ? (
          <>
            <Route index element={authenticated(ClientsPage)} />

            <Route path={RoutePaths.clientsList.path} element={authenticated(ClientsPage)} />
            <Route path={RoutePaths.clientSetup.path} element={authenticated(ClientsPage)} />
            <Route path={RoutePaths.integrationsList.path} element={authenticated(IntegrationsPage)} />
            <Route path={RoutePaths.advertisersList.path} element={authenticated(AdvertisersPage)} />
            <Route path={RoutePaths.campaignsList.path} element={authenticated(CampaignsPage)} />
            <Route path={RoutePaths.campaignManagement.path} element={authenticated(CampaignSetupPage)} />
            <Route path={RoutePaths.campaignDashboard.path} element={authenticated(CampaignDashboardPage)} />

            <Route path={RoutePaths.integrationSetupIndex.path} element={authenticated(IntegrationSetupIndexPage)} />
            <Route path={RoutePaths.integrationSetupDV360.path} element={authenticated(IntegrationSetupDV360Page)} />
            <Route path={RoutePaths.integrationSetupFacebook.path} element={authenticated(IntegrationSetupFacebookPage)} />
            <Route path={RoutePaths.integrationSetupXandr.path} element={authenticated(IntegrationSetupXandrPage)} />

            <Route path={RoutePaths.userManagement.path} element={authenticated(UserManagementPage)} />

            <Route path="/*" element={authenticated(ClientsPage)} />
          </>
        ) : (
          <Route path="/*" element={authenticated(HomePage)} />
        )}
      </>
    </Routes>
  )
}

export default AppRoutes
