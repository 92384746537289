import { gql } from "@apollo/client"
import { DAILY_METRICS_FRAGMENT } from "../fragments"

export const GET_CAMPAIGN_LINE_ITEMS_DAILY_METRICS = gql`
  query getCampaignLineItemsDailyMetrics(
    $campaignType: CampaignType!
    $clientId: Int!
    $platformIntegrationId: Int!
    $platform: Platform!
    $platformAdvertiserId: String!
    $platformCampaignId: ID!
    $pixelIdsSets: [[String!]!]
    $lineItemIds: [ID!]!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    getCampaignLineItemsDailyMetrics(
      campaignType: $campaignType
      clientId: $clientId
      platformIntegrationId: $platformIntegrationId
      platform: $platform
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
      pixelIdsSets: $pixelIdsSets
      lineItemIds: $lineItemIds
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      __typename
      platformCampaignId {
        platform
        id
      }
      dateFrom
      dateTo
      ...DailyMetricsFragment
    }
  }
  ${DAILY_METRICS_FRAGMENT}
`
