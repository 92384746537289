import { gql } from "@apollo/client"

export const CREATE_MANAGED_CAMPAIGN = gql`
  mutation createManagedCampaign(
    $clientId: Int!
    $platform: Platform!
    $platformIntegrationId: Int!
    $platformAdvertiserId: String!
    $platformCampaignId: ID!
    $payload: PlatformCampaignSetupPayload!
  ) {
    createManagedCampaign(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
      payload: $payload
    ) {
      platformCampaignId {
        id
      }
    }
  }
`
