import React, { FC, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Flex, useDisclosure } from "@chakra-ui/react"
import { ErrorBoundary } from "react-error-boundary"
import Button from "../../components/Button"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import Modal from "../../components/Modal"
import ClientsTable from "../../components/tables/ClientsTable"
import { Client } from "../../generated/graphql"
import { CREATE_CLIENT, GET_CLIENTS } from "../../graphql"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canCreate, canView } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import { logError, logUnhandledError } from "../../log"
import AuthenticatedProps from "../AuthenticatedProps"
import ClientSetupForm, { FormValues } from "./components/ClientSetupForm"

type ClientsPageProps = AuthenticatedProps

const ClientsPage: FC<ClientsPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { clientResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const { loading, error, data } = useQuery(GET_CLIENTS)

  const [clientData, setClientData] = useState<Client[]>()
  const [clientName, setClientName] = useState<string | undefined>(undefined)

  const [createClient, { loading: createLoading, error: createError, reset: createReset }] = useMutation(CREATE_CLIENT, {
    refetchQueries: [GET_CLIENTS],
  })

  const { isOpen, onClose, onOpen } = useDisclosure()

  const createClientHandler = async (data: FormValues) => {
    setClientName(data.name)
    await createClient({
      variables: {
        ...data,
      },
    })
    onFinishEditHandler()
  }

  const onFinishEditHandler = () => {
    createReset()
    onClose()
  }

  useEffect(() => {
    if (error) {
      // TODO: error state
      logError("ERROR", error)
    }
    if (createError) {
      // TODO: error state
      logError("ERROR", createError)
    }
    if (data) {
      setClientData(data.getClients as Array<Client>)
    }
  }, [error, createError, data])

  const breadcrumbItems: BreadcrumbItemProps[] = [{ text: "Clients" }]

  const formTitle = `Create New Client`
  const pageTitle = "Clients"

  const fallbackErrorMessage = `There is a problem with ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in ${pageTitle} when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canView(permissions, clientResourceUri) && (
        <ErrorBoundary
          fallback={<ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />}
          onError={logUnhandledError}
        >
          {canCreate(permissions, clientResourceUri) && (
            <Flex justify="start" py={3} mb="-3.25rem">
              <Button onClick={onOpen}>Create Client</Button>
            </Flex>
          )}
          <Modal hasForm isCentered isOpen={isOpen} onClose={onFinishEditHandler} title={formTitle} width="container.sm">
            <ClientSetupForm error={createError} fieldsState={{ clientName }} onSubmitHandler={createClientHandler} />
          </Modal>
          <ClientsTable data={clientData} loading={loading || createLoading} />
        </ErrorBoundary>
      )}
      {!permissionsLoading && !canView(permissions, clientResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default ClientsPage
