import { useRecoilValue } from "recoil"
import permissionsStateAtom from "../atoms/permissionsState"
import { ResourceActions } from "../generated/graphql"
import { resourceActions } from "./usePermissionsLifecycle"

export const usePermissions = () => {
  return useRecoilValue(permissionsStateAtom)
}

export const canView = (permissions: resourceActions, resourceUri: string): boolean => {
  return permissions && Object.keys(permissions).length > 0 ? permissions[resourceUri].includes(ResourceActions.View) : false
}
export const canDoReporting = (permissions: resourceActions, resourceUri: string): boolean => {
  return permissions && Object.keys(permissions).length > 0 ? permissions[resourceUri].includes(ResourceActions.Reporting) : false
}
export const canCreate = (permissions: resourceActions, resourceUri: string): boolean => {
  return permissions && Object.keys(permissions).length > 0 ? permissions[resourceUri].includes(ResourceActions.Create) : false
}
export const canUpdate = (permissions: resourceActions, resourceUri: string): boolean => {
  return permissions && Object.keys(permissions).length > 0 ? permissions[resourceUri].includes(ResourceActions.Update) : false
}
export const canManage = (permissions: resourceActions, resourceUri: string): boolean => {
  return permissions && Object.keys(permissions).length > 0 ? permissions[resourceUri].includes(ResourceActions.Manage) : false
}
export const canDelete = (permissions: resourceActions, resourceUri: string): boolean => {
  return permissions && Object.keys(permissions).length > 0 ? permissions[resourceUri].includes(ResourceActions.Delete) : false
}

export default usePermissions
