import { gql } from "@apollo/client"

export const GET_CLIENT = gql`
  query GetClient($clientId: ID!) {
    getClient(clientId: $clientId) {
      ... on DirectClient {
        clientId
        name
      }

      ... on AgencyClient {
        clientId
        name
      }

      ... on Fifty9AClient {
        clientId
        name
      }
    }
  }
`
