import { gql } from "@apollo/client"

export const GET_PLATFORM_CONVERSION_PIXELS = gql`
  query getPlatformConversionPixels($clientId: Int!, $platform: Platform!, $platformIntegrationId: Int!, $platformAdvertiserId: String) {
    getPlatformConversionPixels(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformAdvertiserId: $platformAdvertiserId
    ) {
      __typename

      clientId
      platformIntegrationId
      platform
      platformConversionPixelId {
        platform
        id
      }
      name
      conversionPixel {
        name
      }
    }
  }
`
