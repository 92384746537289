import { gql } from "@apollo/client"

export const GET_CAMPAIGN_METRICS_USED = gql`
  query getCampaignMetricsUsed(
    $clientId: Int!
    $platformIntegrationId: Int!
    $platform: Platform!
    $platformCampaignId: ID!
    $campaignType: CampaignType!
    $dateFrom: AWSDate!
    $dateTo: AWSDate!
  ) {
    getCampaignMetricsUsed(
      clientId: $clientId
      platformIntegrationId: $platformIntegrationId
      platform: $platform
      platformCampaignId: $platformCampaignId
      campaignType: $campaignType
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`
