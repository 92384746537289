import React, { FC, PropsWithChildren, useMemo, useState } from "react"
import { Box, Flex, HStack, Text } from "@chakra-ui/react"
import { Step, Steps, useSteps } from "chakra-ui-steps"
import colors from "../../theme/colors"
import { StringKeyObject } from "../../types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface WizardStep<D extends any[], SD extends object, T extends WizardStepProps<D, SD>> {
  title: string
  description?: string
  component: FC<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: StringKeyObject<any>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface WizardStepProps<D extends any[], SD extends object> {
  onBack: (data: SD, stepIndex?: number) => void
  onNext: (data: SD, invalidateFollowingSteps?: boolean) => void
  stepIndex: number
  wizardData: D
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface WizardLayoutProps<D extends any[]> {
  title?: string
  subTitle?: string
  steps: WizardStep<D, object, WizardStepProps<D, object>>[]
  initialData: D
  onComplete: (data: D) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WizardLayout = <D extends any[]>({ steps, onComplete, initialData, title, subTitle }: PropsWithChildren<WizardLayoutProps<D>>) => {
  const { nextStep, prevStep, activeStep, setStep } = useSteps({ initialStep: 0 })
  const [currentData, setCurrentData] = useState<D>(initialData)
  const activeStepData = steps[activeStep]

  const onNext = useMemo(
    () =>
      <SD extends object>(stepData: SD, invalidateFollowingSteps = false) => {
        const complete = activeStep >= steps.length - 1
        if (complete) {
          onComplete(mergeData(activeStep, false, initialData, currentData, stepData))
        } else {
          setCurrentData(mergeData(activeStep, invalidateFollowingSteps, initialData, currentData, stepData))
          nextStep()
        }
      },
    [currentData, activeStep, initialData, nextStep, steps, onComplete]
  )

  const onBack = useMemo(
    () =>
      <T extends object>(stepData: T, stepIndex?: number) => {
        setCurrentData(mergeData(activeStep, false, initialData, currentData, stepData))

        if (stepIndex !== undefined) {
          if (stepIndex >= activeStep) throw Error("Attempt to 'go back' to a step after or equal to current step")
          setStep(stepIndex)
        } else {
          prevStep()
        }
      },
    [currentData, activeStep, initialData, prevStep, setStep]
  )

  const stepProps = useMemo(
    () => ({
      onBack,
      onNext,
      stepIndex: activeStep,
      wizardData: currentData,
      ...activeStepData.props,
    }),
    [currentData, activeStep, activeStepData, onBack, onNext]
  )

  const renderProps = useMemo(() => ({ ...stepProps, onNext, onBack }), [stepProps, onNext, onBack])

  return (
    <>
      {currentData && (
        <Flex flexDir="column" marginTop={8} width="100%">
          <HStack marginBottom={2}>
            {title && (
              <Text as="b" fontSize="3xl" color={colors.colors.brand["400"]}>
                {title}
              </Text>
            )}
            {subTitle && (
              <Text as="b" fontSize="2xl" color={colors.colors.brand["200"]}>
                {subTitle}
              </Text>
            )}
          </HStack>
          <Box marginBottom={6}>
            <Steps activeStep={activeStep}>
              {steps.map(({ title, description }) => (
                <Step label={title} key={title}>
                  {description}
                </Step>
              ))}
            </Steps>
          </Box>
          <div>{React.createElement(activeStepData.component, renderProps)}</div>
        </Flex>
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeData = <D extends any[], SD extends object>(currentStep: number, invalidateFollowingSteps: boolean, defaultData: D, data: D, stepData: SD): D => {
  const newData: D = data.slice() as D
  newData[currentStep] = stepData

  return invalidateFollowingSteps
    ? ([
        ...newData.slice(0, currentStep + 1), //
        ...newData.slice(currentStep + 1).map((_, stepIndex) => defaultData[stepIndex]),
      ] as D)
    : newData
}

export default WizardLayout
