import { FC, ReactNode } from "react"
import { ApolloError } from "@apollo/client"
import { Box, BoxProps } from "@chakra-ui/react"

export interface TileProps extends BoxProps {
  children?: ReactNode
  loader?: ReactNode
  loading?: boolean
  error?: ApolloError
  padding?: number
  height?: number | string
  width?: number | string
}

const Tile: FC<TileProps> = ({ loading, loader, error, children, padding = 5, height = "100%", width = "100%", ...rest }) => (
  <Box display="flex" bg="brand.700" borderRadius="4px" height={height} width={width} p={padding} {...rest}>
    {/*// TODO: Barns Error tile*/}
    {loading ? loader : error ? <div>Error Tile: {error.message}</div> : children}
  </Box>
)

export default Tile
