import { CampaignType, MetricName } from "../../generated/graphql"

export type DataPoint = {
  date: Date
  hover: string
  value: number
}

export type HoverData = {
  date: Date
  values: string[]
}

export enum MetricType {
  Monetary = "Monetary",
  Percentage = "Percentage",
  Value = "Value",
}

export enum SegmentType {
  Creatives = "creatives",
  LineItems = "lineItems",
}

export type Pixel = {
  id: string
  name: string
}

export type MetricDataSet = {
  data: DataPoint[]
  name: MetricName
  pixels?: Pixel[]
  target?: number
  minimise: boolean
}

type MetricEntry = {
  type: MetricType
  minimise: boolean
}

const MetricEntriesObject: Record<MetricName, MetricEntry> = {
  [MetricName.ClickReach]: { type: MetricType.Value, minimise: false },
  [MetricName.Clicks]: { type: MetricType.Value, minimise: false },
  [MetricName.CompleteViewsVideo]: { type: MetricType.Value, minimise: false },
  [MetricName.Conversions]: { type: MetricType.Value, minimise: false },
  [MetricName.CoViewedFrequency]: { type: MetricType.Value, minimise: false },
  [MetricName.CoViewedImpressionsReach]: { type: MetricType.Value, minimise: false },
  [MetricName.CoViewedImpressions]: { type: MetricType.Value, minimise: false },
  [MetricName.Cpa]: { type: MetricType.Monetary, minimise: true },
  [MetricName.Cpc]: { type: MetricType.Monetary, minimise: true },
  [MetricName.Cpcv]: { type: MetricType.Monetary, minimise: true },
  [MetricName.Cpm]: { type: MetricType.Monetary, minimise: true },
  [MetricName.Cpv]: { type: MetricType.Monetary, minimise: true },
  [MetricName.Ctr]: { type: MetricType.Percentage, minimise: false },
  [MetricName.Frequency]: { type: MetricType.Value, minimise: false },
  [MetricName.ImpressionsReach]: { type: MetricType.Value, minimise: false },
  [MetricName.Impressions]: { type: MetricType.Value, minimise: false },
  [MetricName.MeasurableImpressions]: { type: MetricType.Value, minimise: false },
  [MetricName.Roas]: { type: MetricType.Percentage, minimise: false },
  [MetricName.Spend]: { type: MetricType.Monetary, minimise: false },
  [MetricName.TotalReach]: { type: MetricType.Value, minimise: false },
  [MetricName.Vcpm]: { type: MetricType.Monetary, minimise: true },
  [MetricName.Vcr]: { type: MetricType.Percentage, minimise: false },
  [MetricName.Viewability]: { type: MetricType.Percentage, minimise: false },
  [MetricName.ViewableFrequency]: { type: MetricType.Value, minimise: false },
  [MetricName.ViewableImpressionsReach]: { type: MetricType.Value, minimise: false },
  [MetricName.ViewableImpressions]: { type: MetricType.Value, minimise: false },
}

export const MetricEntries = Object.entries(MetricEntriesObject).map(([name, entry]) => ({
  name: name as MetricName,
  ...entry,
}))

const OrderOfMetricsObject: Record<MetricName, null> = {
  [MetricName.Spend]: null,
  [MetricName.Impressions]: null,
  [MetricName.MeasurableImpressions]: null,
  [MetricName.ViewableImpressions]: null,
  [MetricName.CoViewedImpressions]: null,
  [MetricName.Cpm]: null,
  [MetricName.Vcpm]: null,
  [MetricName.Clicks]: null,
  [MetricName.Ctr]: null,
  [MetricName.Cpc]: null,
  [MetricName.Viewability]: null,
  [MetricName.Frequency]: null,
  [MetricName.CoViewedFrequency]: null,
  [MetricName.ViewableFrequency]: null,
  [MetricName.ClickReach]: null,
  [MetricName.ImpressionsReach]: null,
  [MetricName.TotalReach]: null,
  [MetricName.CoViewedImpressionsReach]: null,
  [MetricName.ViewableImpressionsReach]: null,
  [MetricName.Conversions]: null,
  [MetricName.Cpa]: null,
  [MetricName.CompleteViewsVideo]: null,
  [MetricName.Cpcv]: null,
  [MetricName.Cpv]: null,
  [MetricName.Roas]: null,
  [MetricName.Vcr]: null,
}

export const OrderOfMetrics = Object.keys(OrderOfMetricsObject) as MetricName[]

export type MetricIdentifier = {
  name: MetricName
  pixelIds?: string[]
}

export type MetricsComparisonPair = {
  originalMetric: MetricIdentifier
  currencySymbol: string
  isSelected?: boolean
  isQRTZ: boolean
  metric1: MetricIdentifier
  metric2: MetricIdentifier
}

export const CampaignTypeMetrics: Record<CampaignType, MetricName[]> = {
  [CampaignType.Display]: [
    MetricName.Clicks,
    MetricName.Cpc,
    MetricName.Cpm,
    MetricName.Ctr,
    MetricName.Impressions,
    MetricName.Spend,
    MetricName.Vcpm,
    MetricName.Viewability,
  ],
  [CampaignType.Video]: [
    MetricName.Clicks,
    MetricName.CompleteViewsVideo,
    MetricName.Cpc,
    MetricName.Cpm,
    MetricName.Cpv,
    MetricName.Cpcv,
    MetricName.Ctr,
    MetricName.Impressions,
    MetricName.Spend,
    MetricName.Vcr,
    MetricName.Vcpm,
    MetricName.Viewability,
  ],
  [CampaignType.Youtube]: [
    MetricName.Clicks,
    MetricName.CompleteViewsVideo,
    MetricName.Cpc,
    MetricName.Cpcv,
    MetricName.Cpm,
    MetricName.Cpv,
    MetricName.Ctr,
    MetricName.CoViewedFrequency,
    MetricName.CoViewedImpressions,
    MetricName.CoViewedImpressionsReach,
    MetricName.Spend,
    MetricName.Vcpm,
    MetricName.Viewability,
    MetricName.Vcr,
  ],
}
