import { gql } from "@apollo/client"

export const UPDATE_CLIENT = gql`
  mutation updateClient($clientId: Int!, $name: String!, $type: ClientType!) {
    updateClient(clientId: $clientId, name: $name, type: $type) {
      ... on DirectClient {
        clientId
        name
      }

      ... on AgencyClient {
        clientId
        name
      }

      ... on Fifty9AClient {
        clientId
        name
      }
    }
  }
`
