import { ChangeEvent, FC } from "react"
import { Flex, HStack, Switch, Text } from "@chakra-ui/react"
import DSPLogoMapper from "../../../components/DSPLogoMapper"
import Tile from "../../../components/Tile"
import { Platform } from "../../../generated/graphql"

type CampaignInfoProps = {
  campaignName: string
  isManaged: boolean
  platform: Platform
  toggleCampaignManagement: (event: ChangeEvent<HTMLInputElement>) => void
}

const CampaignInfo: FC<CampaignInfoProps> = ({ campaignName, isManaged, platform, toggleCampaignManagement }) => (
  <Tile py={3}>
    <HStack w="100%" justifyContent="space-between">
      <Flex>
        <DSPLogoMapper platform={platform} />
        <Text overflowWrap="anywhere" ml={2}>
          {campaignName}
        </Text>
      </Flex>
      <Flex ml="auto" alignItems="center">
        <Text fontSize="1.2rem">{isManaged ? "Managed:" : "Unmanaged:"}</Text>
        <Switch isChecked={isManaged} onChange={toggleCampaignManagement} ml={2} size="lg" />
      </Flex>
    </HStack>
  </Tile>
)

export default CampaignInfo
