import { graphql } from "msw"

export const getPlatformCampaigns = graphql.query("GetPlatformCampaigns", (req, res, ctx) => {
  return res(
    ctx.data({
      getPlatformCampaigns: [
        {
          __typename: "PlatformCampaignWithAssignees",
          id: "3-DV360-1011521773",
          clientId: 1,
          name: "UK_Hamilton_CY23_Q2_DISPLAY_PROS",
          platform: "DV360",
          managed: false,
          managedCampaignData: null,
          platformCampaignId: {
            __typename: "PlatformCampaignId",
            id: "1011521773",
            platform: "DV360",
          },
          platformAdvertiserId: {
            __typename: "PlatformAdvertiserId",
            id: "1231378503",
            platform: "DV360",
          },
          assignees: [],
        },
        {
          __typename: "PlatformCampaignWithAssignees",
          id: "3-DV360-1011521794",
          clientId: 1,
          name: "UK_Hamilton_CY23_Q2_DISPLAY_RTG",
          platform: "DV360",
          managed: true,
          managedCampaignData: {
            isAutoApproved: false,
            __typename: "ManagedPlatformCampaignData",
          },
          platformCampaignId: {
            __typename: "PlatformCampaignId",
            id: "1011521794",
            platform: "DV360",
          },
          platformAdvertiserId: {
            __typename: "PlatformAdvertiserId",
            id: "1231378503",
            platform: "DV360",
          },
          assignees: [
            {
              clientId: 1,
              userId: "44",
              name: {
                first: "Mircea",
                last: "Ungureanu",
                __typename: "UserNames",
              },
              email: "mircea.ungureanu@59a.co.uk",
              __typename: "UserProfile",
            },
          ],
        },
      ],
    })
  )
})
