import { UseFormSetValue } from "react-hook-form"
import { CampaignType, KpiName, KpiType } from "../../../generated/graphql"
import { Step2Data } from "../../../pages/CampaignSetupPage/CampaignSetup/ManageCampaignData"
import { Optional, StringKeyObject } from "../../../types"
import { Option } from "../SelectInput/SelectBaseUI"

export enum KpiTargetType {
  Integer = "INTEGER",
  Monetary = "MONETARY",
  Percentage = "PERCENTAGE",
}

export enum KpiFullName {
  Cpa = "Cost Per Action",
  Cpc = "Cost Per Click",
  Cpcv = "Cost Per Completed View",
  Cpm = "Cost Per Thousand Impressions",
  Cpv = "Cost Per View",
  Ctr = "Click Through Rate",
  Roas = "Return on Ad Spend",
  Vcpm = "Cost Per Viewable Thousand Impressions",
  Vcr = "Video Completed Rate",
}

export interface FormKpiTarget {
  integer?: string
  monetary?: string
  percentage?: string
}

interface FormKpiConstraint {
  target?: FormKpiTarget
}

interface FormKpiConstraints {
  min: FormKpiConstraint
  max: FormKpiConstraint
}

export interface FormKpi {
  name?: string
  target?: FormKpiTarget
  constraints?: FormKpiConstraints
  weight?: string
  pixelIds?: string[] | string
}

export interface FormValues {
  mainKpi?: FormKpi
  secondaryKpis?: Array<FormKpi>
}

export interface KpiStepData {
  type: string
  kpi: KPI
  target: string
  minTarget: string
  maxTarget: string
  pixelIds?: string[]
  weight: string
}

export interface KPI {
  name: KpiName
  fullName?: KpiFullName
  targetType: KpiTargetType
  requiresPixelId: boolean
  minimise: boolean
  campaignType?: CampaignType[]
  logisticRegressionSupported: boolean
  mandatorySecondaryKpis: KpiName[]
}

export const KpiEntries: KPI[] = [
  {
    name: KpiName.Cpv,
    fullName: KpiFullName.Cpv,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    campaignType: [CampaignType.Video, CampaignType.Youtube],
    logisticRegressionSupported: false,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Cpm,
    fullName: KpiFullName.Cpm,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    logisticRegressionSupported: true,
    mandatorySecondaryKpis: [KpiName.Ctr],
  },
  {
    name: KpiName.Vcpm,
    fullName: KpiFullName.Vcpm,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    campaignType: [CampaignType.Display],
    logisticRegressionSupported: true,
    mandatorySecondaryKpis: [KpiName.Ctr],
  },
  {
    name: KpiName.Cpa,
    fullName: KpiFullName.Cpa,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: true,
    minimise: true,
    logisticRegressionSupported: true,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Viewability,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
    logisticRegressionSupported: false,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Cpc,
    fullName: KpiFullName.Cpc,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    logisticRegressionSupported: true,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Ctr,
    fullName: KpiFullName.Ctr,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
    logisticRegressionSupported: true,
    mandatorySecondaryKpis: [KpiName.Cpm],
  },
  {
    name: KpiName.Cpcv,
    fullName: KpiFullName.Cpcv,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    campaignType: [CampaignType.Video, CampaignType.Youtube],
    logisticRegressionSupported: false,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Vcr,
    fullName: KpiFullName.Vcr,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
    campaignType: [CampaignType.Video, CampaignType.Youtube],
    logisticRegressionSupported: false,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Reach,
    targetType: KpiTargetType.Integer,
    requiresPixelId: false,
    campaignType: [CampaignType.Youtube],
    minimise: false,
    logisticRegressionSupported: false,
    mandatorySecondaryKpis: [],
  },
  {
    name: KpiName.Roas,
    fullName: KpiFullName.Roas,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
    logisticRegressionSupported: false,
    mandatorySecondaryKpis: [],
  },
]

export const getKpiByName = (kpis: KPI[], searchKpi: string | Option): KPI => {
  let result: Optional<KPI> = undefined
  if (typeof searchKpi === "string") {
    result = kpis.find((kpi) => kpi.name.toLowerCase() === searchKpi.toLowerCase())
  } else {
    result = kpis.find((kpi) => kpi.name.toLowerCase() === searchKpi.value.toLowerCase())
  }
  if (result) return result
  throw Error(`No KPI with name: '${searchKpi}'`)
}

export const buildInitialValueKpi = (kpiStepData: Optional<KpiStepData>): FormKpi => {
  const targetBlock = (integer: string, percentage: string, monetary: string) => ({
    integer,
    percentage,
    monetary,
  })

  if (!kpiStepData) {
    return {
      name: undefined,
      target: targetBlock("", "", ""),
      constraints: {
        min: { target: targetBlock("", "", "") },
        max: { target: targetBlock("", "", "") },
      },
    }
  }

  const buildTargetBlock = (kpi: KPI, value: string) => {
    switch (kpi.targetType) {
      case KpiTargetType.Integer:
        return targetBlock(value, "", "")
      case KpiTargetType.Percentage:
        return targetBlock("", value, "")
      case KpiTargetType.Monetary:
        return targetBlock("", "", value)
      default:
        throw Error(`Unknown valueType: '${kpi.targetType}'`)
    }
  }

  const { kpi, target, minTarget, maxTarget, weight, pixelIds } = kpiStepData

  return {
    name: kpi.name,
    target: buildTargetBlock(kpi, target),
    constraints: {
      min: { target: minTarget ? buildTargetBlock(kpi, minTarget) : targetBlock("", "", "") },
      max: { target: maxTarget ? buildTargetBlock(kpi, maxTarget) : targetBlock("", "", "") },
    },
    weight,
    pixelIds,
  }
}

export const buildInitialFormValues = (kpis: KPI[], stepData: Step2Data): FormValues => {
  const mainKpi = stepData?.kpis.find((kpi) => kpi.type === KpiType.Main)
  const secondaryKpis = stepData?.kpis.filter((kpi) => kpi.type === KpiType.Secondary) || []
  return {
    mainKpi: buildInitialValueKpi(mainKpi),
    secondaryKpis: secondaryKpis //
      .map((kpi) => buildInitialValueKpi(kpi) as FormKpi)
      .filter((kpi) => kpi.name !== undefined),
  }
}

export const buildKpiStepData = (kpis: KPI[], kpiValues: FormKpi, type: KpiType): KpiStepData => {
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const kpi = getKpiByName(kpis, kpiValues.name!)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseValue = (valueBlock?: StringKeyObject<any>): string => {
    if (kpi.targetType === KpiTargetType.Monetary) {
      if (valueBlock?.monetary === "" || valueBlock?.monetary === "0") {
        return ""
      } else {
        return parseFloat(valueBlock?.monetary).toString()
      }
    } else if (kpi.targetType === KpiTargetType.Percentage) {
      if (valueBlock?.percentage === "" || valueBlock?.percentage === "0") {
        return ""
      } else {
        return parseFloat(valueBlock?.percentage).toString()
      }
    } else if (kpi.targetType === KpiTargetType.Integer) {
      if (valueBlock?.integer === "" || valueBlock?.integer === "0") {
        return ""
      } else {
        return parseFloat(valueBlock?.integer).toString()
      }
    }
    return ""
  }

  const pixelIds = typeof kpiValues.pixelIds === "string" ? [kpiValues.pixelIds] : kpiValues.pixelIds

  return {
    kpi,
    type,
    target: parseValue(kpiValues.target) ?? "",
    minTarget: parseValue(kpiValues.constraints?.min?.target) ?? "",
    maxTarget: parseValue(kpiValues.constraints?.max?.target) ?? "",
    weight: kpiValues.weight ?? "",
    pixelIds: kpiValues.name === KpiName.Cpa ? pixelIds : undefined,
  }
}

export const buildStepData = (kpis: KPI[], values: FormValues): Step2Data => {
  if (values.mainKpi?.name === undefined) {
    return {
      kpis: [],
    }
  }
  if (values.secondaryKpis) {
    return {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      kpis: [buildKpiStepData(kpis, values.mainKpi!, KpiType.Main)].concat(
        values.secondaryKpis?.filter((kpi) => kpi.name).map((kpi) => buildKpiStepData(kpis, kpi, KpiType.Secondary))
      ),
    }
  }
  return {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    kpis: [buildKpiStepData(kpis, values.mainKpi!, KpiType.Main)],
  }
}

export const resetCpmKpi = (setValue: UseFormSetValue<FormValues>) => {
  setValue("mainKpi.target.monetary", "")
  setValue("mainKpi.constraints.min.target.monetary", "")
  setValue("mainKpi.constraints.max.target.monetary", "")
  setValue("mainKpi.pixelIds", [])
  setValue("secondaryKpis", [
    {
      name: KpiName.Ctr,
      target: { percentage: "" },
      constraints: {
        min: { target: { percentage: "" } },
        max: { target: { percentage: "" } },
      },
    },
  ])
}

export const resetVcpmKpi = (setValue: UseFormSetValue<FormValues>) => {
  setValue("mainKpi.target.monetary", "")
  setValue("mainKpi.constraints.min.target.monetary", "")
  setValue("mainKpi.constraints.max.target.monetary", "")
  setValue("mainKpi.pixelIds", [])
  setValue("secondaryKpis", [
    {
      name: KpiName.Ctr,
      target: { percentage: "" },
      constraints: {
        min: { target: { percentage: "" } },
        max: { target: { percentage: "" } },
      },
    },
  ])
}

export const resetCtrKpi = (setValue: UseFormSetValue<FormValues>) => {
  setValue("mainKpi.target.percentage", "")
  setValue("mainKpi.constraints.min.target.percentage", "")
  setValue("mainKpi.constraints.max.target.percentage", "")
  setValue("mainKpi.pixelIds", [])
  setValue("secondaryKpis", [
    {
      name: KpiName.Cpm,
      target: { monetary: "" },
      constraints: {
        min: { target: { monetary: "" } },
        max: { target: { monetary: "" } },
      },
    },
  ])
}

export const resetCpaKpi = (setValue: UseFormSetValue<FormValues>) => {
  setValue("mainKpi.target.monetary", "")
  setValue("mainKpi.constraints.min.target.monetary", "")
  setValue("mainKpi.constraints.max.target.monetary", "")
  setValue("mainKpi.pixelIds", [])
  setValue("secondaryKpis", undefined)
}

export const resetCpcKpi = (setValue: UseFormSetValue<FormValues>) => {
  setValue("mainKpi.target.monetary", "")
  setValue("mainKpi.constraints.min.target.monetary", "")
  setValue("mainKpi.constraints.max.target.monetary", "")
  setValue("mainKpi.pixelIds", [])
  setValue("secondaryKpis", undefined)
}
