import { gql } from "@apollo/client"

export const GET_ALL_PLATFORM_INTEGRATION_SCHEDULES = gql`
  query getAllPlatformIntegrationSchedules {
    getAllPlatformIntegrationSchedules {
      scheduleId
      scheduleRunType
      state
      scheduleCronExpression
      nextRunTimestamp
      payload {
        activityType
        clientId
        platformDataType
        platform
        format
        platformIntegrationId
        outputDestinations
        granularity
      }
      description
    }
  }
`
