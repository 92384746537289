import { gql } from "@apollo/client"
import { DAILY_METRICS_FRAGMENT } from "../fragments"

export const GET_CAMPAIGN_LAST_7_DAILY_METRICS = gql`
  query getCampaignLast7DailyMetrics(
    $campaignType: CampaignType!
    $clientId: Int!
    $platformIntegrationId: Int!
    $platform: Platform!
    $platformCampaignId: ID!
    $platformAdvertiserId: String!
    $pixelIdsSets: [[String!]!]
  ) {
    getCampaignLast7DailyMetrics(
      clientId: $clientId
      platformIntegrationId: $platformIntegrationId
      platform: $platform
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
      campaignType: $campaignType
      pixelIdsSets: $pixelIdsSets
    ) {
      platformCampaignId {
        platform
        id
      }
      dateFrom
      dateTo
      ...DailyMetricsFragment
    }
  }
  ${DAILY_METRICS_FRAGMENT}
`
