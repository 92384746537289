import { FC } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { Flex, Grid, GridItem, Tooltip } from "@chakra-ui/react"
import { TileProps } from "../../../layouts/TilesGrid"
import GridState from "../../../layouts/TilesGrid/GridState"
import CampaignDashboardStateController from "../../../pages/CampaignDashboardPage/types"
import TrendGraph from "../TrendGraph"
import TrendGraphNoData from "../TrendGraph/TrendGraphNoData"
import { MetricDataSet } from "../types"

const TrendsStrip: FC<TileProps<CampaignDashboardStateController, GridState, MetricDataSet[]>> = ({ dashboardController, tileData, queryDetails }) => (
  <>
    <Flex h={6} align="center">
      <Tooltip aria-label={"Tooltip for 7 Day Trend Graphs"} label={"These trend graphs show the previous 7 days data"} placement="right-start">
        <InfoIcon color={"brand.400"} w={4} h={4} />
      </Tooltip>
    </Flex>
    <Grid
      width="container.xl"
      maxWidth="container.xl"
      height="199px"
      columnGap="20px"
      templateAreas={`"1 2 3 4 5"`}
      templateColumns="repeat(5, minmax(0, 1fr))"
    >
      {tileData.map((metricDataSet, index) => (
        <GridItem key={index} area={index}>
          {metricDataSet.data.length ? (
            <TrendGraph //
              loading={queryDetails?.loading}
              error={queryDetails?.error}
              metricDataSet={metricDataSet}
              currencySymbol={dashboardController.campaignCurrencySymbol}
            />
          ) : (
            <TrendGraphNoData />
          )}
        </GridItem>
      ))}
    </Grid>
  </>
)

export default TrendsStrip
