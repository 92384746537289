import { gql } from "@apollo/client"

export const GET_ROLE_WITH_PERMISSIONS = gql`
  query getRoleWithPermissions($clientId: Int!, $roleId: Int!) {
    getRoleWithPermissions(clientId: $clientId, roleId: $roleId) {
      clientId
      roleId
      name
      description
      permissions {
        client
        user
        platformIntegration
        platformAdvertiser
        platformCampaign
      }
    }
  }
`
