import { FC } from "react"
import { Box, Center, Text, VStack } from "@chakra-ui/react"
import { NoData } from "../../../images"
import colors from "../../../theme/colors"
import Tile from "../../Tile"

const TrendGraphNoData: FC = () => (
  <Tile p={0}>
    <Box p={2} m={0} width="100%">
      <Center>
        <VStack alignItems="center" mt={6}>
          <Text fontSize="lg" textAlign="center">
            We have no trend data for the last 7 days. :-(
          </Text>
          <NoData color={colors.colors.brand["400"]} height="25%" width="25%" />
        </VStack>
      </Center>
    </Box>
  </Tile>
)

export default TrendGraphNoData
