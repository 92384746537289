import { graphql } from "msw"

export const enableAutoApproveCampaign = graphql.mutation("EnableAutoApproveCampaign", (req, res, ctx) => {
  return res(
    ctx.data({
      enableAutoApproveCampaign: {
        __typename: "PlatformCampaign",
        id: "3-DV360-1011521794",
        clientId: 1,
        name: "UK_Hamilton_CY23_Q2_DISPLAY_RTG",
        currency: "",
        platform: "DV360",
        managed: true,
        managedCampaignData: {
          strategy: "QRTZ_SCORE",
          controlAlgorithmStrategy: null,
          isAutoApproved: true,
          campaignType: "DISPLAY",
          buyingType: null,
          budgetType: "MONETARY",
          marginType: null,
          revenueModel: "COST_PLUS",
          margin: 0.0,
          minMargin: null,
          maxMargin: null,
          kpis: [
            {
              type: "MAIN",
              name: "CPM",
              target: 3.0,
              min: 2.0,
              max: 5.0,
              weight: 9,
              pixelIds: null,
              minimise: true,
              __typename: "CampaignKPI",
            },
          ],
          parameterGroups: [
            "LINE_ITEM_BIDDING_MODEL",
            "LINE_ITEM_BUDGET",
            "LINE_ITEM_CREATIVES_WEIGHTING",
            "LINE_ITEM_DAY_PARTING",
            "LINE_ITEM_FOLD_POSITION",
            "LINE_ITEM_FREQUENCY",
            "LINE_ITEM_PLACEMENT_AUDIENCES_DOMAINS",
            "LINE_ITEM_RECENCY",
            "LINE_ITEM_VIEWABILITY_RATE",
          ],
          __typename: "ManagedPlatformCampaignData",
        },
        platformAdvertiserId: {
          __typename: "PlatformAdvertiserId",
          id: "1231378503",
          platform: "DV360",
        },
        platformCampaignId: {
          __typename: "PlatformCampaignId",
          id: "1011521794",
          platform: "DV360",
        },
      },
    })
  )
})
