import { gql } from "@apollo/client"

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!, $type: ClientType!) {
    createClient(name: $name, type: $type) {
      ... on DirectClient {
        clientId
        name
      }

      ... on AgencyClient {
        clientId
        name
      }

      ... on Fifty9AClient {
        clientId
        name
      }
    }
  }
`
